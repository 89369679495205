<template>
  <router-view />
</template>

<script>


import HelloWorld from './components/HelloWorld.vue'
import Test from './components/Test.vue'
import Contact from './components/Contact.vue'
import OneSignal from '@onesignal/onesignal-vue3';

export default {
  name: 'App',
  components: {
    HelloWorld,
    Test,
    Contact 
  },
  mounted() {
    this.$OneSignal.showSlidedownPrompt();
    this.$OneSignal.on("permissionPromptDisplay", (e) => {
      console.warn("permissionPromptDisplay", e)
    })
    this.$OneSignal.on("subscriptionChange", e => {
      console.warn("subscriptionChange", e);
    })
    this.$OneSignal.on("notificationDisplay", e => {
      console.warn("notificationDisplay", e);
    })
    this.$OneSignal.on("notificationDismiss", e => {
      console.warn("notificationDismiss", e);
    })
  }
  
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
