<template>
    <div class="hello">
      {{$store.state.count}}
      <div>{{$store.state.numar}}</div>
    </div>
    <router-link to="/">Home</router-link>
    <router-link to="/about">About</router-link>
    <router-link to="/contact">Contact</router-link>
    <div @click="increment()">INCREMENT</div>
</template>
  
  <script> 
 
 
  export default {
    name: 'Test',
    methods: {
        increment() {
            this.$store.commit('increment')
        },
        TimeoutFunction: function () {
            let vm = this;
            setTimeout(function () {
                vm.$store.commit("seteazaNumar", 3);
            }, 1000);
        }
    },
    mounted () {
        this.TimeoutFunction()
        
    }
  }
  </script>
  
 
  