<template>
  <div class="hello">
    <img alt="Vue logo" src="@/assets/logo.png"/>
    <h1>{{ msg }}</h1>
    {{$store.state.count}}
    _________________
    <br/>
    <router-link to="/">Home</router-link>
    <router-link to="/about">About</router-link>
    <router-link to="/contact">Contact</router-link>
    <div @click="increment()">INCREMENT</div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  methods: {
      increment() {
          this.$store.commit('increment')
          console.log(this.$store.state.count)
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
