import { createApp } from 'vue'
import OneSignalVuePlugin from '@onesignal/onesignal-vue3'
import App from './App.vue'
import {  createRouter, createWebHistory } from 'vue-router'
import HelloWorld from './components/HelloWorld.vue'
import Test from './components/Test.vue'
import ContactUs from './components/Contact.vue'
import { createStore } from 'vuex'

// 1. Define route components.
// These can be imported from other files
const Home = { template: '<div>Home</div>' }
const About = { template: '<div>About</div>' }
const Contact = { template: '<div>Contact</div>' }
// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
  { path: '/', name: 'Home', component: HelloWorld },
  { path: '/about', name: 'About', component: Test },
  { path: '/contact', name: 'Contact', component: ContactUs },
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
})
export default router

// Create a new store instance.
const store = createStore({
  state () {
    return {
      count: 0,
      numar: 22
    }
  },
  mutations: {
    increment (state) {
      state.count++
    },
    seteazaNumar (state, value) {
      state.numar = value;
    }
  }
})

const app = createApp(App)

app.use(router)
app.use(store)
app.use(OneSignalVuePlugin, {
  appId: '5e7891df-40ba-412c-99d5-49c34e2b1a24',
  allowLocalhostAsSecureOrigin: true
})
app.mount('#app')


