<template>
    <div class="hello">
      {{$store.state.count}}
    </div>
    <router-link to="/">Home</router-link>
    <router-link to="/about">About</router-link>
    <router-link to="/contact">Contact</router-link>
    <div @click="increment()">INCREMENT</div>
</template>
  
  <script>
  export default {
    name: 'Test',
    methods: {
        increment() {
            this.$store.commit('increment')
            console.log(this.$store.state.count)
        }
    }
  }
  </script>
  
 
  